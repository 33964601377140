<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        {{
          (types === "diller" && "Дилер") ||
            (types === "kindergarten" && "Детский сад") ||
            (types === "default" && "Компания") ||
            (types === "service" && "Сервис") ||
            "Загрузка"
        }}
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle></FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="Название"
            ></ValidationInputField>
            <ValidationInputField
              rules="required"
              label="Полное название"
              v-model="full_org_name"
              validate-name="Полное название"
            >
            </ValidationInputField>
            <ValidationAutocompleteField
              :search-function="citySearch"
              rules="required"
              label="Город"
              v-model="city"
              :return-object="false"
              validate-name="Город"
            />
            <ValidationAutocompleteField
              :search-function="dillerSearch"
              v-if="
                getCurrentRole === 70 &&
                  !(types === 'diller') &&
                  !(types === 'oppen')
              "
              rules=""
              label="Дилер"
              v-model="diller"
              validate-name="Дилер"
            />
            <ValidationSelectField
              v-model="ownership"
              :items="organizationType"
              rules="required"
              label="Тип организации"
              validate-name="Тип организации"
            ></ValidationSelectField>
            <ValidationInputField
              v-model="address"
              label="Юр. адрес"
              :rules="'min:1|max:255|required'"
              validate-name="Юр. адрес"
            ></ValidationInputField>
            <ValidationInputField
              rules=""
              v-model="actual_address"
              label="Фактический адрес"
            />
            <ValidationInputField
              v-model="phone_number"
              mask="+7 (###) ###-##-##"
              rules="required|all_phone"
              label="Телефон"
              validate-name="Телефон"
            ></ValidationInputField>
            <ValidationInputField
              rules="required|email"
              v-model="email"
              label="E-mail"
              validate-name="E-mail"
            ></ValidationInputField>
            <ValidationInputField v-model="director" label="Руководитель" />
            <ValidationAutocompleteField
              :search-function="serviceSearch"
              :disabled="!city"
              rules="required"
              label="Обслуживающая организация"
              v-model="serviceOrg"
              validate-name="Обслуживающая организация"
            />
            <ValidationInputField
              v-if="showContrAgent"
              mask="##-########"
              label="Код контрагента"
              v-model="code_contractor"
              validate-name="Код контрагента"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->

        <!--    Информация о договоре    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Информация о договоре</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              v-model="number"
              rules="required"
              label="Номер договора"
            />
            <ValidationFileField
              accept=".docx,.doc,.pdf"
              v-model="file"
              :rules="'required'"
              label="Файл"
            />
          </FormInputBlock>
        </div>
        <!--    Информация о договоре    -->

        <!--    Реквизиты    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Реквизиты</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required|min:10"
              mask="##########"
              validate-name="ИНН"
              v-model="TIN"
              label="ИНН"
            />
            <ValidationInputField
              :rules="ownership == 'ИП' ? 'required|min:15' : 'required|min:13'"
              :mask="ownership == 'ИП' ? '###############' : '#############'"
              validate-name="ОГРН"
              label="ОГРН"
              v-model="PSRN"
            />
            <ValidationInputField
              rules="required|min:9"
              validate-name="КПП"
              mask="#########"
              v-model="IEC"
              label="КПП"
            />
            <ValidationInputField
              rules="required|min:9|max:9"
              mask="#########"
              validate-name=""
              v-model="RCBIC"
              label="БИК"
            />
            <ValidationInputField
              rules="required"
              v-model="bank"
              validate-name="Название банка"
              label="Название банка"
            />
            <ValidationInputField
              rules="required|min:20"
              v-model="CA"
              label="К/с"
              mask="####################"
            />

            <ValidationInputField
              rules="required|min:20"
              v-model="giro"
              label="Р/с"
              mask="####################"
            />
          </FormInputBlock>
        </div>
        <!--    Реквизиты    -->
        <div>
          <FormCardTitle class="data__text">
            Стандартный тариф
          </FormCardTitle>
          <FormInputBlock>
            <p>Стоимость карты</p>
            <p>Период действия карты</p>

            <v-spacer />
            <ValidationInputField
              v-model="cardPrices"
              rules="required|number|isEmpty"
              label="Стоимость карты"
              validate-name="стоимость карты"
            />
            <ValidationSelectField
              v-model="validity"
              :items="[
                { text: '12', value: 12 },
                { text: '24', value: 24 },
                { text: '36', value: 36 },
                { text: '48', value: 48 },
                { text: '60 ', value: 60 }
              ]"
              rules="required"
              label="Срок действия, мес."
              validate-name="срок действия"
            ></ValidationSelectField>
          </FormInputBlock>
          <FormInputBlock>
            <p>Стоимость тарифа для приложения</p>
            <v-spacer />
            <v-spacer />
            <ValidationInputField
              v-model="tariff_name"
              rules="required"
              label="Название тарифа"
              validate-name="название тарифа"
            />
            <v-spacer />
            <v-spacer />

            <ValidationInputField
              rules="required|number|isEmpty"
              type="number"
              v-model="app_price_3"
              label="Стоимость за 3 месяца"
              validate-name="Стоимость за 3 месяцев"
              prefix="₽"
            />
            <ValidationInputField
              rules="required|number|isEmpty"
              type="number"
              v-model="app_price_12"
              label="Стоимость за 12 месяцев"
              validate-name="Стоимость за 12 месяцев"
              prefix="₽"
            />
          </FormInputBlock>
        </div>

        <div>
          <FormCardTitle class="data__text">
            Количество пользователей бесплатной группы
          </FormCardTitle>

          <FormInputBlock>
            <ValidationInputField
              rules="required"
              v-model="userCount"
              label="Количество"
              mask="###"
            />
          </FormInputBlock>
        </div>
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editCompany)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getCitiesTypesClearRequest } from "@/helpers/api/cities";
import {
  getAllOrganizationsClearRequest,
  getOrganizationsRequest
} from "@/helpers/api/organizations";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import { getKindergartenByIdClearRequest } from "@/helpers/api/kindergartens";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import moment from "moment";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
export default {
  name: "EditKindergartenPage",
  components: {
    ValidationFileField,
    ValidationAutocompleteField,
    ValidationSelectField,
    ValidationInputField,
    MainLayout,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout
  },
  data() {
    return {
      loading: false,
      showContrAgent: false,
      isSubmit: false,
      organizationTypes: Object.entries(
        dictionariesHelper.organizationOwnership
      ).map(([value, text]) => ({ value, text })),
      dillerSearch: async value => {
        return [
          { text: "Oppen", value: 1 },
          ...(
            await getOrganizationsRequest({
              query: { search: value, types: "diller" }
            })
          ).data.results.map(el => ({
            text: el.name,
            value: el.id
          }))
        ];
      },

      citySearch: async value => {
        return (
          await getCitiesTypesClearRequest({
            search: value,
            offset: null
            // type: "kindergarten"
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      serviceSearch: async value => {
        return (
          await getAllOrganizationsClearRequest({
            query: { search: value }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    organizationType() {
      return this.organizationTypes;
    },
    userCount: {
      get() {
        return this.$store.getters.getOrganizationEditForm
          .free_participant_limit.value
          ? this.$store.getters.getOrganizationEditForm.free_participant_limit
              .value
          : 0;
      },
      set(newValue) {
        this.$store.commit("changeEditObjectValue", {
          fieldName: "free_participant_limit",
          value: Number(newValue)
        });
      }
    },
    getCurrentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    // appPrice: {
    //   get() {
    //     return this.$store.getters.getOrganizationEditForm.group_config
    //       .app_price.value;
    //   },
    //   set(newValue) {
    //     this.$store.commit("changeEditOrganizationsValue", {
    //       fieldName: "group_config.app_price",
    //       value: newValue
    //     });
    //   }
    // },
    cardPrices: {
      get() {
        return this.$store.getters.getOrganizationEditForm.group_config
          .card_price.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "group_config.card_price",
          value: newValue.replace(/^0+/, "")
        });
      }
    },
    app_price_3: {
      get() {
        return this.$store.getters.getOrganizationEditForm.group_config
          .app_price_3.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "group_config.app_price_3",
          value: newValue.replace(/^0+/, "")
        });
      }
    },
    app_price_12: {
      get() {
        return this.$store.getters.getOrganizationEditForm.group_config
          .app_price_12.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "group_config.app_price_12",
          value: newValue.replace(/^0+/, "")
        });
      }
    },
    tariff_name: {
      get() {
        return this.$store.getters.getOrganizationEditForm.group_config
          .tariff_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "group_config.tariff_name",
          value: newValue
        });
      }
    },
    validity: {
      get() {
        return this.$store.getters.getOrganizationEditForm.group_config
          .card_duration.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "group_config.card_duration",
          value: newValue
        });
      }
    },
    threeDayCount: {
      get() {
        return Math.abs(moment().diff(moment().add(3, "month"), "day"));
      }
    },
    twelveDayCount: {
      get() {
        return Math.abs(moment().diff(moment().add(12, "month"), "day"));
      }
    },
    diller: {
      get() {
        if (this.$store.getters.getOrganizationEditForm.diller.value) {
          this.show();
        }

        return this.$store.getters.getOrganizationEditForm.diller.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "diller",
          value: newValue
        });
      }
    }, //дилер
    ownership: {
      get() {
        return this.$store.getters.getOrganizationEditForm.ownership.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "ownership",
          value: newValue
        });
      }
    }, //"ИП", "ООО" и тд
    full_org_name: {
      get() {
        return this.$store.getters.getOrganizationEditForm.full_org_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "full_org_name",
          value: newValue
        });
      }
    }, //Полное название
    name: {
      get() {
        return this.$store.getters.getOrganizationEditForm.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "name",
          value: newValue
        });
        this.$store.commit("changeEditObjectValue", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    number: {
      get() {
        return this.$store.getters.getOrganizationEditForm.number.value;
      },
      set(newVal) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "number",
          value: newVal
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getOrganizationEditForm.file.value;
      },
      set(newVal) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "file",
          value: newVal
        });
      }
    },
    code_contractor: {
      get() {
        return this.$store.getters.getOrganizationEditForm.code_contractor
          .value;
      },

      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "code_contractor",
          value: newValue
        });
      }
    },
    director: {
      get() {
        return this.$store.getters.getOrganizationEditForm.director.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "director",
          value: newValue
        });
      }
    }, //директор
    types: {
      get() {
        return this.$store.getters.getOrganizationEditForm.type.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "type",
          value: newValue
        });
      }
    },
    TIN: {
      get() {
        return this.$store.getters.getOrganizationEditForm.TIN.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "TIN",
          value: newValue
        });
      }
    }, //инн
    PSRN: {
      get() {
        return this.$store.getters.getOrganizationEditForm.PSRN.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "PSRN",
          value: newValue
        });
      }
    }, //ОГРН
    IEC: {
      get() {
        return this.$store.getters.getOrganizationEditForm.IEC.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "IEC",
          value: newValue
        });
      }
    }, //КПП
    CA: {
      get() {
        return this.$store.getters.getOrganizationEditForm.CA.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "CA",
          value: newValue
        });
      }
    }, //"Корреспондентский счёт"
    giro: {
      get() {
        return this.$store.getters.getOrganizationEditForm.giro.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "giro",
          value: newValue
        });
      }
    }, //Расчётный счёт
    RCBIC: {
      get() {
        return this.$store.getters.getOrganizationEditForm.RCBIC.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "RCBIC",
          value: newValue
        });
      }
    }, //БИК
    bank: {
      get() {
        return this.$store.getters.getOrganizationEditForm.bank.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "bank",
          value: newValue
        });
      }
    }, //Название банка
    address: {
      get() {
        return this.$store.getters.getOrganizationEditForm.address.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "address",
          value: newValue
        });
        this.$store.commit("changeEditObjectValue", {
          fieldName: "address",
          value: newValue
        });
      }
    }, //Адрес
    phone_number: {
      get() {
        return this.$store.getters.getOrganizationEditForm.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    email: {
      get() {
        return this.$store.getters.getOrganizationEditForm.email.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "email",
          value: newValue
        });
      }
    },
    city: {
      get() {
        return this.$store.getters.getOrganizationEditForm.city.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "city",
          value: newValue
        });
        this.$store.commit("changeEditObjectValue", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    acquiring_provider: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_provider
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_provider",
          value: newValue
        });
      }
    }, //Эквайринг
    actual_address: {
      get() {
        return this.$store.getters.getOrganizationEditForm.actual_address.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "actual_address",
          value: newValue
        });
        this.$store.commit("changeEditObjectValue", {
          fieldName: "address",
          value: newValue
        });
      }
    },
    acquiring_entity: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_entity
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_entity",
          value: newValue
        });
      }
    },
    acquiring_login: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_login
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_login",
          value: newValue
        });
      }
    },
    acquiring_password: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_password
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_password",
          value: newValue
        });
      }
    },
    serviceOrg: {
      get() {
        if (
          this.$store.getters.getOrganizationEditForm.service_organizations
            .value != null &&
          this.$store.getters.getOrganizationEditForm.service_organizations
            .value.length > 0
        ) {
          return this.$store.getters.getOrganizationEditForm
            ?.service_organizations?.value[0]?.id;
        } else {
          return null;
        }
      },
      set(newValue) {
        this.$store.commit(
          "change_service_organizations_EditOrganizationsValue",
          newValue
        );
        this.$store.commit("changeEditObjectValue", {
          fieldName: "service_organization",
          value: newValue
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearEditCompany");
    next();
  },
  created() {
    if (this.$store.getters.getCurrentRole == 60) {
      this.show();
    }

    this.$store.dispatch("prepareEditKindergarten", {
      id: this.$route.params.id
    });
  },
  methods: {
    async show() {
      if (this.$store.getters.getOrganizationEditForm.diller.value != 1) {
        await getOrganizationsRequest({
          query: {
            ids:
              this.$store.getters.getOrganizationEditForm.diller.value ||
              this.$store.getters.getCurrentOrganization,
            types: "diller"
          }
        }).then(res => {
          if (
            res.data.results[0] &&
            res.data.results[0].is_vb_diller &&
            res.data.results.length == 1
          ) {
            this.showContrAgent = true;
          } else {
            this.showContrAgent = false;
          }
        });
      } else {
        this.showContrAgent = false;
      }
    },
    editCompany() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("editCompany", { id: this.$route.params.id })
        .then(async () => {
          const kindergartenObjectId = (
            await getKindergartenByIdClearRequest({ id: self.$route.params.id })
          ).data.object.id;
          return kindergartenObjectId;
        })
        .then(id => {
          this.$store.dispatch("editObject", { id });
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.kindergarten)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
